@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/calibri.ttf'),
  url('../fonts/calibri.woff');
}

@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/calibri.ttf'),
  url('../fonts/calibri.woff');
}

@font-face {
  font-family: 'Calibri';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/calibrib.ttf'),
  url('../fonts/calibrib.woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'),
  local('Montserrat-Regular'),
  url('../fonts/Montserrat-Regular.ttf'),
  url('../fonts/Montserrat-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'),
  local('Montserrat-Medium'),
  url('../fonts/Montserrat-Medium.ttf'),
  url('../fonts/Montserrat-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local('Montserrat SemiBold'),
  local('Montserrat-Medium'),
  url('../fonts/Montserrat-SemiBold.ttf'),
  url('../fonts/Montserrat-SemiBold.woff2') format('woff');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'),
  local('Montserrat-Bold'),
  url('../fonts/Montserrat-Bold.ttf'),
  url('../fonts/Montserrat-Bold.woff') format('woff');
}

@font-face {
  font-family: 'NovelPro';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/NovelPro-SemiBold.otf'),
  url('../fonts/NovelPro-SemiBold.woff');
}

@font-face {
  font-family: 'NovelPro';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/NovelPro-Regular.otf');
}

@import "~font-awesome/scss/font-awesome";

.fas {
  @extend .fa;
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../fonts/Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/Roboto-Medium.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../fonts/Roboto-MediumItalic.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('../fonts/OpenSans-SemiBold.ttf');
}
